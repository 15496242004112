body{
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    margin: 0;
}
.MuiBackdrop-root {
    background-color: transparent !important;
}
.slick-slider .slick-track, .slick-slider .slick-list {
    height: 100%;
}

.slick-list {
    overflow: visible;
}

.slick-initialized .slick-slide {
    justify-content: center;
    align-items: center;
    display: flex;
}

.center .slick-slide.slick-center {
    -ms-transform: scale(1.3);
     transform: scale(1.3);
 }

 .slick-dots {
     position: relative;
     margin-top: 4%;
 }

 .hero-gradient {
    position: relative;
    width: 100%;
    margin-top: -2%;
    padding: 60px 12px 40px;
    background: #403999;
    height: 35vh;
}
#wave {
    position: absolute;
    height: 600px;
    top: 0;
    left: 0;
    width: 100%;
}

.slick-dots li button:before {
    color: #403999 !important;
    font-size: 15px;
}

.slick-dots li.slick-active button:before {
    opacity: 1
}